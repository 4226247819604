var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useKillSessionMutation, useResetUserMutation } from 'api';
import { ReactComponent as CautionIcon } from 'assets/icons/cautionSign.svg';
import { ContactsSelection, Button, Modal, ReusableSvg, Loader } from 'components';
import { MY_PROFILE_FORM_ID } from 'constants/htmlElements';
import { ROUTES } from 'constants/routes';
import { globalMessages, onboardingRequestsMessages } from 'i18n/messages';
import { ButtonsWrapper, FlexContainer, Message, OrdinaryDiv, Title } from 'shared/styled/Layout';
import { authSelector, configSelector, userSelector } from 'store';
import { pathnameToPageTitle, trackDataLayerEvent } from 'utils/googleAnalytics';
import { AUTH_CHANNEL_MAPPER_REVERSED } from 'utils/mappers';
import { notificationSuccessHandler } from 'utils/notificationHandlers';
import { MyProfileHeader, Item, ItemWrapper, SelectProfileWrapper } from './MyProfile.styles';
export const MyProfile = ({ closeProfileModal }) => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const { authToken = '' } = useSelector(authSelector);
    const { AUTH_CHANNEL } = useSelector(configSelector);
    const { id: userId, names, language } = useSelector(userSelector);
    const [killSession, { isLoading: isKillSessionLoading }] = useKillSessionMutation();
    const [resetUser, { isLoading: isResetUserLoading }] = useResetUserMutation();
    const [displayResetOnboardingModal, setDisplayResetOnboardingModal] = useState(false);
    const handleLogout = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield killSession({
                token: authToken !== null && authToken !== void 0 ? authToken : '',
            }).unwrap();
            trackDataLayerEvent({
                event: 'logout',
                clusterName: null,
                corporateScope: AUTH_CHANNEL_MAPPER_REVERSED[AUTH_CHANNEL],
                eventContext: 'Digital Product Portal',
                language: language,
                pageId: location.pathname,
                pageLayout: 'desktop',
                pageTemplate: null,
                pageTitle: pathnameToPageTitle(location.pathname),
                pageType: 'Antragsstrecke',
                pageURL: location.pathname,
                logoutMethod: 'active',
                processName: 'FX Corporate Onboarding',
            });
            closeProfileModal();
            navigate(ROUTES.LOGIN_PORTALS);
        }
        catch (err) {
            console.error(err);
        }
    });
    const showResetOnboardingModal = () => {
        setDisplayResetOnboardingModal(true);
    };
    const hideResetOnboardingModal = () => {
        setDisplayResetOnboardingModal(false);
    };
    const resetWholeOnboarding = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            hideResetOnboardingModal();
            yield resetUser(userId).unwrap();
            closeProfileModal();
            notificationSuccessHandler(formatMessage(onboardingRequestsMessages.resetOnboardingSuccessMessage));
            navigate(ROUTES.LANDING_PAGE);
        }
        catch (err) {
            console.log(err);
        }
    });
    return (React.createElement(OrdinaryDiv, null,
        (isResetUserLoading || isKillSessionLoading) && React.createElement(Loader, null),
        React.createElement(Modal, { language: language, name: 'Reset Confirmation Modal', isOpen: displayResetOnboardingModal, closeModal: hideResetOnboardingModal, actionHandler: resetWholeOnboarding },
            React.createElement(FlexContainer, { direction: "column" },
                React.createElement(ReusableSvg, { Icon: CautionIcon, marginLeft: "auto", marginRight: "auto", marginBottom: "28px" }),
                React.createElement(Title, { textAlign: "center" }, formatMessage(onboardingRequestsMessages.resetOnboardingApplicationInfo)),
                React.createElement(Message, { textAlign: "center" }, formatMessage(onboardingRequestsMessages.areYouSureYouWantToResetOnboardingApplication)))),
        React.createElement(MyProfileHeader, null, formatMessage(globalMessages.myOnboardingProfile)),
        React.createElement(OrdinaryDiv, null,
            React.createElement(ItemWrapper, null,
                formatMessage(globalMessages.name),
                ": ",
                React.createElement(Item, null, names)),
            React.createElement(SelectProfileWrapper, null,
                React.createElement(ContactsSelection, { variant: "primary", formId: MY_PROFILE_FORM_ID, navigateAfterSubmit: false, displayDataProtectionNotification: false, hasGreenBackground: false, withPhoneSection: true, onSubmit: () => {
                        closeProfileModal();
                    } })),
            React.createElement(ButtonsWrapper, { marginTop: "3.5rem" },
                React.createElement(Button, { onClick: closeProfileModal, variant: "secondary" }, formatMessage(globalMessages.cancel)),
                React.createElement(Button, { onClick: showResetOnboardingModal, variant: "secondary" }, formatMessage(onboardingRequestsMessages.resetOnboarding)),
                React.createElement(Button, { onClick: handleLogout, variant: "secondary" }, formatMessage(globalMessages.logout)),
                React.createElement(Button, { form: MY_PROFILE_FORM_ID, type: "submit", variant: "primary" }, formatMessage(globalMessages.save))))));
};
export default MyProfile;
