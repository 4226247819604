import { styled } from 'styled-components';
export const MyProfileHeader = styled.header `
	padding-bottom: ${({ theme }) => theme.spacing(1)};
	margin-bottom: ${({ theme }) => theme.spacing(2)};
	border-bottom: ${({ theme }) => `${theme.spacing(0.3)} solid ${theme.colors.lightPetrol}`};
	font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
	font-size: ${({ theme }) => theme.fontSizes[20]};
`;
export const ItemWrapper = styled.p `
	margin: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(0)}`};
`;
export const Item = styled.span `
	font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
`;
export const SelectProfileWrapper = styled.div `
	padding-top: ${({ theme }) => theme.spacing(2)};
`;
