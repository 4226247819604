import { defineMessages } from 'react-intl';
export const globalMessages = defineMessages({
    welcomeOnboarding: {
        id: 'Global.welcomeOnboarding',
        defaultMessage: 'Welcome to Commerzbank eOnboarding',
    },
    welcomeOnboardingFirstQuestion: {
        id: 'Global.welcomeOnboardingFirstQuestion',
        defaultMessage: 'Do you want to trade independently, whenever a market is open anywhere in the world?',
    },
    welcomeOnboardingSecondQuestion: {
        id: 'Global.welcomeOnboardingSecondQuestion',
        defaultMessage: 'Would you like to be able to confirm your trades with a single click?',
    },
    welcomeOnboardingThirdQuestion: {
        id: 'Global.welcomeOnboardingThirdQuestion',
        defaultMessage: 'Would you like to benefit from the expertise of our Commerzbank experts?',
    },
    welcomeOnboardingInfo: {
        id: 'Global.welcomeOnboardingInfo',
        defaultMessage: "Here you're able to request exactly this!",
    },
    welcomeOnboardingFxltInfo: {
        id: 'Global.welcomeOnboardingFxltInfo',
        defaultMessage: "{FxLiveTraderWebVideoUrl} is Commerzbank's foreign exchange trading platform for corporate and business clients. The web version runs on all common end devices – even mobile or on a tablet. This gives you the flexibility to trade over 100 currency pairs and access current price developments.",
    },
    welcomeOnboardingLiveConfirmInfo: {
        id: 'Global.welcomeOnboardingLiveConfirmInfo',
        defaultMessage: '{LiveConfirmVideoUrl} is an online application for the confirmation of your foreign exchange and financial derivatives transactions. This means that all business parameters will be available to you in electronic form shortly after trading.',
    },
    findClients: {
        id: 'Global.findClients',
        defaultMessage: 'Find single or multiple clients you want to onboard for FX Trading or Confirm Trades',
    },
    continue: {
        id: 'Global.continue',
        defaultMessage: 'Continue',
    },
    nextStep: {
        id: 'Global.nextStep',
        defaultMessage: 'Next step',
    },
    emailAddress: {
        id: 'Global.emailAddress',
        defaultMessage: 'Email address',
    },
    cancel: {
        id: 'Global.cancel',
        defaultMessage: 'Cancel',
    },
    next: {
        id: 'Global.next',
        defaultMessage: 'Next',
    },
    logout: {
        id: 'Global.logout',
        defaultMessage: 'Logout',
    },
    and: {
        id: 'Global.and',
        defaultMessage: 'and',
    },
    yes: {
        id: 'Global.yes',
        defaultMessage: 'Yes',
    },
    no: {
        id: 'Global.no',
        defaultMessage: 'No',
    },
    all: {
        id: 'Global.all',
        defaultMessage: 'All',
    },
    onboardingRequests: {
        id: 'Global.onboardingRequests',
        defaultMessage: 'eOnboarding Requests',
    },
    howToGetInTouch: {
        id: 'Global.howToGetInTouch',
        defaultMessage: 'How can we get in touch?',
    },
    contactDetails: {
        id: 'Global.contactDetails',
        defaultMessage: 'Contact Details',
    },
    accountSelection: {
        id: 'Global.accountSelection',
        defaultMessage: 'Account Selection',
    },
    onboardingForms: {
        id: 'Global.onboardingForms',
        defaultMessage: 'eOnboarding Forms',
    },
    onboardingStatus: {
        id: 'Global.onboardingStatus',
        defaultMessage: 'Onboarding Status',
    },
    clientSpecificForms: {
        id: 'Global.clientSpecificForms',
        defaultMessage: 'CLIENT-SPECIFIC FORMS',
    },
    toBeCompleted: {
        id: 'Global.toBeCompleted',
        defaultMessage: 'To be completed',
    },
    completed: {
        id: 'Global.completed',
        defaultMessage: 'Completed',
    },
    printed: {
        id: 'Global.printed',
        defaultMessage: 'Printed',
    },
    generalForms: {
        id: 'Global.generalForms',
        defaultMessage: 'GENERAL FORMS',
    },
    formsPreview: {
        id: 'Global.formsPreview',
        defaultMessage: 'Forms Preview',
    },
    formsToBeCompleted: {
        id: 'Global.formsToBeCompleted',
        defaultMessage: 'Forms to be completed/accepted in the next steps',
    },
    allAccounts: {
        id: 'Global.allAccounts',
        defaultMessage: 'All Accounts',
    },
    addClients: {
        id: 'Global.addClients',
        defaultMessage: 'Add client(s)',
    },
    addAccounts: {
        id: 'Global.addAccounts',
        defaultMessage: 'Add Account(s)',
    },
    goBack: {
        id: 'Global.goBack',
        defaultMessage: 'Go back',
    },
    reportProblem: {
        id: 'Global.reportProblem',
        defaultMessage: 'Report a Problem',
    },
    notFound: {
        id: 'Global.notFound',
        defaultMessage: "Oops! We couldn't find what you are looking for!",
    },
    removeClientConfirm: {
        id: 'Global.removeClientConfirm',
        defaultMessage: 'Are you sure you want to remove this client?',
    },
    removeClientsReminder: {
        id: 'Global.removeClientsReminder',
        defaultMessage: 'You can add it again, if you change your mind',
    },
    proceedWithChangesOrCancel: {
        id: 'Global.proceedWithChangesOrCancel',
        defaultMessage: 'Continue to proceed with the changes, or Cancel to continue with current options',
    },
    English: {
        id: 'Global.English',
        defaultMessage: 'English',
    },
    German: {
        id: 'Global.German',
        defaultMessage: 'German',
    },
    unsupportedBrowser: {
        id: 'Global.unsupportedBrowser',
        defaultMessage: 'Looks like you are using an unsupported browser. Please use or download one of the following',
    },
    submit: {
        id: 'Global.submit',
        defaultMessage: 'Submit',
    },
    view: {
        id: 'Global.view',
        defaultMessage: 'View',
    },
    save: {
        id: 'Global.save',
        defaultMessage: 'Save',
    },
    myOnboardingProfile: {
        id: 'Global.myOnboardingProfile',
        defaultMessage: 'My Onboarding Profile',
    },
    name: {
        id: 'Global.name',
        defaultMessage: 'Name',
    },
    emails: {
        id: 'Global.emails',
        defaultMessage: 'Emails',
    },
    activeEmail: {
        id: 'Global.activeEmail',
        defaultMessage: 'Active Email',
    },
    addEmail: {
        id: 'Global.addEmail',
        defaultMessage: 'Add Email',
    },
    invalidEmail: {
        id: 'Global.invalidEmail',
        defaultMessage: 'Invalid Email',
    },
    changeEmail: {
        id: 'Global.changeEmail',
        defaultMessage: 'Change Email',
    },
    invalidValueProvided: {
        id: 'Global.invalidValueProvided',
        defaultMessage: 'Invalid value provided for {value}',
    },
    minPhoneLength: {
        id: 'Global.minPhoneLength',
        defaultMessage: 'Phone number must be at least 5 characters long',
    },
    whatWentWrong: {
        id: 'Global.whatWentWrong',
        defaultMessage: 'Please share what went wrong and how we may contact you to help resolve it.',
    },
    describeWhatYouIntendedToDo: {
        id: 'Global.describeWhatYouIntendedToDo',
        defaultMessage: 'Please try to describe briefly what you intended to do',
    },
    unsupportedUserInfo: {
        id: 'Global.unsupportedUserInfo',
        defaultMessage: 'We are very sorry, but our trading applications are designed for legal entities only and not suitable for individuals. Please reach out to your respective Commerzbank advisor.',
    },
    previous: {
        id: 'Global.previous',
        defaultMessage: 'Previous',
    },
    downloadAs: {
        id: 'Global.downloadAs',
        defaultMessage: 'Download as',
    },
    telephone: {
        id: 'Global.telephone',
        defaultMessage: 'Telephone',
    },
    mobile: {
        id: 'Global.mobile',
        defaultMessage: 'Mobile Number',
    },
    howToBeReached: {
        id: 'Global.howToBeReached',
        defaultMessage: 'Tell us how you want to be reached by our team',
    },
    successForm: {
        id: 'Global.successForm',
        defaultMessage: 'Successfully sent form!',
    },
    phoneNumberNotValid: {
        id: 'Global.phoneNumberNotValid',
        defaultMessage: 'Phone number is not valid!',
    },
    contactUs: {
        id: 'Global.contactUs',
        defaultMessage: 'Contact us / Request a callback',
    },
    email: {
        id: 'Global.email',
        defaultMessage: 'Email',
    },
    howMayWeHelpYou: {
        id: 'Global.howMayWeHelpYou',
        defaultMessage: 'How may we help you?',
    },
    brieflyDescribeQuery: {
        id: 'Global.brieflyDescribeQuery',
        defaultMessage: 'Please briefly describe your query',
    },
    allAccountsHaveBeenAdded: {
        id: 'Global.allAccountsHaveBeenAdded',
        defaultMessage: 'All accounts to which you have a power of attorney have already been added. Please complete additional power of attorneys for any other accounts you wish to onboard.',
    },
    backToPortal: {
        id: 'Global.backToPortal',
        defaultMessage: 'Back to Portal',
    },
    takeScreenShot: {
        id: 'Global.takeScreenShot',
        defaultMessage: 'Take Screenshot',
    },
    retakeScreenShot: {
        id: 'Global.retakeScreenShot',
        defaultMessage: 'Retake Screenshot',
    },
    deleteScreenshot: {
        id: 'Global.deleteScreenshot',
        defaultMessage: 'Delete Screenshot',
    },
    theBankAtYourSide: {
        id: 'Global.theBankAtYourSide',
        defaultMessage: 'The bank at your side',
    },
    terms: {
        id: 'Global.terms',
        defaultMessage: 'Terms',
    },
    security: {
        id: 'Global.security',
        defaultMessage: 'Security',
    },
    imprint: {
        id: 'Global.imprint',
        defaultMessage: 'Imprint',
    },
    legalNotices: {
        id: 'Global.legalNotices',
        defaultMessage: 'Legal Notices',
    },
    regulatoryDisclosures: {
        id: 'Global.regulatoryDisclosures',
        defaultMessage: 'Regulatory Disclosures',
    },
    back: {
        id: 'Global.back',
        defaultMessage: 'Back',
    },
    chatHistory: {
        id: 'Global.chatHistory',
        defaultMessage: 'Chat history',
    },
    text: {
        id: 'Global.text',
        defaultMessage: 'Text',
    },
    submittedOn: {
        id: 'Global.submittedOn',
        defaultMessage: 'Submitted on',
    },
    noHistoryItems: {
        id: 'Global.noHistoryItems',
        defaultMessage: 'No chat history items found',
    },
    businessHoursNotification: {
        id: 'Global.businessHoursNotification',
        defaultMessage: 'Thank you for your request. We will try to get back to you as soon as possible. Please keep in mind our business hours which are {businessHours} so please understand that any request outside these hours might be responded with a delay only.',
    },
    mondayThroughFridayBusinessHours: {
        id: 'Global.mondayThroughFridayBusinessHours',
        defaultMessage: 'Mon - Fri 08:00 - 17:30 CET',
    },
    Unknown: {
        id: 'Global.unknown',
        defaultMessage: 'Unknown',
    },
    AvailableProductInfo: {
        id: 'Global.AvailableProductInfo',
        defaultMessage: 'Available',
    },
    RequestedProductInfo: {
        id: 'Global.RequestedProductInfo',
        defaultMessage: 'This is currently being set up',
    },
    EnabledProductInfo: {
        id: 'Global.EnabledProductInfo',
        defaultMessage: 'You already have this product',
    },
    MissingPowerOfAttorneyProductInfo: {
        id: 'Global.MissingPowerOfAttorneyProductInfo',
        defaultMessage: "You don't currently have the necessary power of attorney for this product",
    },
    role: {
        id: 'Global.role',
        defaultMessage: 'Role',
    },
    dateOfBirth: {
        id: 'Global.dateOfBirth',
        defaultMessage: 'Date of Birth',
    },
    firstName: {
        id: 'Global.firstName',
        defaultMessage: 'First name',
    },
    lastName: {
        id: 'Global.lastName',
        defaultMessage: 'Last name',
    },
    countryCode: {
        id: 'Global.countryCode',
        defaultMessage: 'Country Code',
    },
    serverUnavailable: {
        id: 'Global.serverUnavailable',
        defaultMessage: 'Sorry, our site is currently unavailable due to essential maintenance, please try again in a couple of minutes...',
    },
    noInternetConnection: {
        id: 'Global.noInternetConnection',
        defaultMessage: 'You appear to be offline? Please check your internet connection...',
    },
    here: {
        id: 'Global.here',
        defaultMessage: 'here',
    },
    cookiesManagement: {
        id: 'Global.cookiesManagement',
        defaultMessage: 'Cookies Management',
    },
    cookiesDisabledWarning: {
        id: 'Global.cookiesDisabledWarning',
        defaultMessage: "It seems you've disabled cookies.{br}We need them to sign you in. Don't worry they won't contain any personal information. Click {infoLink} for more info",
    },
    somethingWentWrong: {
        id: 'Global.somethingWentWrong',
        defaultMessage: 'Sorry, unfortunately something went wrong! We have logged the error and our team will look into it shortly. In the meantime you can contact us via the button below.',
    },
    retry: {
        id: 'Global.retry',
        defaultMessage: 'Retry',
    },
    reload: {
        id: 'Global.reload',
        defaultMessage: 'Reload',
    },
    delayInDataFetching: {
        id: 'Global.delayInDataFetching',
        defaultMessage: 'Fetching the information is taking longer than usual. Please be patient.',
    },
    howToEnableCookies: {
        id: 'Global.howToEnableCookies',
        defaultMessage: 'How to enable cookies?',
    },
    howToEnableCookiesOnEdge: {
        id: 'Global.howToEnableCookiesOnEdge',
        defaultMessage: '{icon} Settings / Cookies und site permissions / Manage and delete cookies and site data / Allow (Add) / cbportal.commerzbank.com/fkdb/',
    },
    howToEnableCookiesOnChrome: {
        id: 'Global.howToEnableCookiesOnChrome',
        defaultMessage: '{icon} Settings / Privacy and security / Cookies and other site data / Sites that can always use cookies / Add / cbportal.commerzbank.com/fkdb/',
    },
    howToEnableCookiesOnMozilla: {
        id: 'Global.howToEnableCookiesOnMozilla',
        defaultMessage: '{icon} Settings / Privacy & Security / Select the standard option',
    },
    howToEnableCookiesOnSafari: {
        id: 'Global.howToEnableCookiesOnSafari',
        defaultMessage: '{icon} Preferences... / Privacy / Cookies and website data / Deselect Block all cookies.',
    },
    login: {
        id: 'Global.login',
        defaultMessage: 'Login',
    },
    videos: {
        id: 'Global.videos',
        defaultMessage: 'Videos',
    },
    restart: {
        id: 'Global.restart',
        defaultMessage: 'Restart',
    },
    appointment: {
        id: 'Global.appointment',
        defaultMessage: 'Appointment',
    },
    contactDetailsUpdateSuccess: {
        id: 'Global.contactDetailsUpdateSuccess',
        defaultMessage: 'Successfully updated your contact details.',
    },
    warning: {
        id: 'Global.warning',
        defaultMessage: 'Warning',
    },
    success: {
        id: 'Global.success',
        defaultMessage: 'Success',
    },
    error: {
        id: 'Global.error',
        defaultMessage: 'Error',
    },
    provideLater: {
        id: 'Global.provideLater',
        defaultMessage: 'Provide later',
    },
    notRelevant: {
        id: 'Global.notRelevant',
        defaultMessage: 'Not relevant',
    },
    taxId: {
        id: 'Global.taxId',
        defaultMessage: 'Tax ID',
    },
    taxIdInputPlaceholder: {
        id: 'Global.taxIdInputPlaceholder',
        defaultMessage: 'e.g. 22 222 222 222',
    },
    taxIdSubmissionSuccess: {
        id: 'Global.taxIdSubmissionSuccess',
        defaultMessage: 'Thanks for providing your Tax ID.',
    },
    taxIdRequiredLength: {
        id: 'Global.taxIdRequiredLength',
        defaultMessage: 'Tax Id should contain 11 digits',
    },
    contactUsMessage: {
        id: 'Global.contactUsMessage',
        defaultMessage: "In the meantime you can click the {contactUsIcon} icon below and we'll be in touch to see if we can help you sooner.",
    },
    loginFailedMessage: {
        id: 'Global.loginFailedMessage',
        defaultMessage: 'Sorry a problem has occurred loading our application.{br} Please click reload to try again, or if the problem persists please contact us via the {contactUsIcon} button below.',
    },
    userFailedMessage: {
        id: 'Global.userFailedMessage',
        defaultMessage: 'Sorry a problem has occurred loading your user data.{br} Please click reload to try again, or if the problem persists please contact us via the {contactUsIcon} button below.',
    },
    loadingInProgress: {
        id: 'Global.loadingInProgress',
        defaultMessage: 'Loading in progress...',
    },
    verify: {
        id: 'Global.verify',
        defaultMessage: 'Verify',
    },
    resendCode: {
        id: 'Global.resendCode',
        defaultMessage: 'Resend code',
    },
    permission: {
        id: 'Global.permission',
        defaultMessage: 'Permission',
    },
    phonePlaceholder: {
        id: 'Global.phonePlaceholder',
        defaultMessage: 'e.g. +49 XXX XXXXX',
    },
    emailPlaceholder: {
        id: 'Global.emailPlaceholder',
        defaultMessage: 'e.g. firstname.lastname@company.com',
    },
});
