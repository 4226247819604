var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api, requester } from 'api/shared';
import { SERVER_ERRORS } from 'constants/index';
import { invalidateSession, loginServerFailure, setAuthTokenData, setMockedLoginCookieToken, } from 'store/reducers';
import { initialUserState, userApiUtils } from './userApi';
export const LOGIN_ENDPOINTS = {
    login: () => '/login',
    mockLogin: () => '/login/mockportallogin',
    keepAlive: () => '/login/keepalive',
    killSession: () => '/login/logout',
};
const loginApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation({
            query: (data) => requester.post(LOGIN_ENDPOINTS.login(), data),
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setAuthTokenData(data));
                    }
                    catch ({ error }) {
                        dispatch(invalidateSession());
                        if (SERVER_ERRORS.includes(error === null || error === void 0 ? void 0 : error.status)) {
                            dispatch(loginServerFailure());
                        }
                    }
                });
            },
        }),
        mockLogin: build.mutation({
            query: (data) => requester.post(LOGIN_ENDPOINTS.mockLogin(), data),
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setMockedLoginCookieToken(data));
                    }
                    catch ({ error }) {
                        dispatch(invalidateSession());
                        if (SERVER_ERRORS.includes(error === null || error === void 0 ? void 0 : error.status)) {
                            dispatch(loginServerFailure());
                        }
                    }
                });
            },
        }),
        killSession: build.mutation({
            query: (data) => requester.put(LOGIN_ENDPOINTS.killSession(), data),
            onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        yield queryFulfilled;
                        const { decodedAuthTokenNameId = '' } = getState().auth;
                        dispatch(userApiUtils.updateQueryData('getUser', decodedAuthTokenNameId, (draft) => (Object.assign(Object.assign({}, initialUserState), { language: draft.language }))));
                        dispatch(invalidateSession());
                    }
                    catch ({ error }) {
                        dispatch(invalidateSession()); // invalidate the session nevertheless
                    }
                });
            },
        }),
        keepAlive: build.mutation({
            query: (data) => requester.put(LOGIN_ENDPOINTS.keepAlive(), data),
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setAuthTokenData(data));
                    }
                    catch ({ error }) {
                        dispatch(invalidateSession());
                    }
                });
            },
        }),
        keepAliveTrigger: build.query({
            queryFn: () => {
                return { data: void null };
            },
            onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        yield queryFulfilled;
                        const { auth: { authToken, sessionValid }, config: { AUTH_CHANNEL }, } = getState();
                        if (!sessionValid || !authToken) {
                            return;
                        }
                        yield loginApiEndpoints.keepAlive.initiate({
                            token: authToken,
                            userAgent: navigator.userAgent,
                            channel: AUTH_CHANNEL,
                        })(dispatch, getState, _);
                    }
                    catch ({ error }) {
                        console.error(error);
                    }
                });
            },
        }),
    }),
});
export const loginApiEndpoints = loginApi.endpoints;
export const { useLoginMutation, useMockLoginMutation, useKillSessionMutation, useKeepAliveMutation, useKeepAliveTriggerQuery, } = loginApi;
