var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useReportProblemMutation } from 'api';
import { Button, TextArea } from 'components';
import { reportProblemFormInitialValues } from 'constants/index';
import { globalMessages } from 'i18n/messages';
import { ButtonsSection } from 'shared/styled/Layout';
import { ReportProblemSchema } from 'shared/validationSchemas';
import { userSelector } from 'store';
export const ReportProblemForm = ({ onSubmit, closeModal, infoText, placeholder, }) => {
    const { formatMessage } = useIntl();
    const { id: userId } = useSelector(userSelector);
    const [reportProblem] = useReportProblemMutation();
    const handleSubmit = ({ details }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield reportProblem({ details, userId }).unwrap();
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
        }
        catch (err) {
            console.log(err);
        }
    });
    return (React.createElement(Formik, { initialValues: reportProblemFormInitialValues, validationSchema: ReportProblemSchema, enableReinitialize: true, validateOnMount: true, onSubmit: handleSubmit }, ({ isValid, isSubmitting, dirty }) => {
        const formDisabled = isSubmitting || !dirty || !isValid;
        return (React.createElement(FormikForm, null,
            React.createElement(TextArea, { name: "details", minHeight: "30vh", infoText: infoText, placeholder: placeholder }),
            React.createElement(ButtonsSection, null,
                React.createElement(Button, { variant: "primary", onClick: closeModal }, formatMessage(globalMessages.cancel)),
                React.createElement(Button, { disabled: formDisabled, variant: formDisabled ? 'secondary' : 'primary', type: "submit" }, formatMessage(globalMessages.submit)))));
    }));
};
export default ReportProblemForm;
