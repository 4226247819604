var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { toPng } from 'html-to-image';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useContactTeamMutation } from 'api';
import { Button, Checkbox, Input, TextArea, ToggleSwitch } from 'components';
import { DEFAULT_COUNTRY_CODE } from 'constants/common';
import { contactUsFormInitialValues } from 'constants/formsInitialStates';
import { globalMessages } from 'i18n/messages';
import { ButtonsSection, FlexContainer, Paragraph } from 'shared/styled/Layout';
import { ContactUsSchema } from 'shared/validationSchemas';
import { userSelector } from 'store';
import { notificationAlertHandler } from 'utils/notificationHandlers';
import { ContactUsInputWrapper, NeedHelpHeading, NeedHelpSection, CheckboxWrapper, StyledScreenshotImage, ScreenshotWrapper, ScreenshotImageWrapper, FullScreenshotImage, StyledBigScreenshotImage, ScreenshotButtonsWrapper, UserPreviouslyAskedQuestions, UserPreviouslyAskedQuestion, UserQuestionCreatedDate, UserPreviouslyAskedQuestionWrapper, } from './ContactUsForm.styles';
export const ContactUsForm = ({ onSubmit, closeModal, toggleModalVisibility, enableScreenshotFunctionality = true, infoText, placeholder, }) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { id: userId, selectedEmail: userSelectedEmail, selectedTelephone: userSelectedTelephone, contactRequestHistories: userQuestionsHistory = [], } = useSelector(userSelector);
    const [showFullScreenImage, setShowFullScreenImage] = useState(false);
    const [showPreviouslyAskedQuestions, setShowPreviouslyAskedQuestions] = useState(false);
    const [contactTeam] = useContactTeamMutation();
    const initialFormValues = Object.assign(Object.assign({}, contactUsFormInitialValues), { email: userSelectedEmail || '', phone: (() => {
            try {
                const phoneNumber = parsePhoneNumber(userSelectedTelephone !== null && userSelectedTelephone !== void 0 ? userSelectedTelephone : '');
                return phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.formatInternational();
            }
            catch (e) {
                return '';
            }
        })() });
    const handleOpenPicture = () => {
        setShowFullScreenImage(true);
    };
    const handleToggleView = () => setShowPreviouslyAskedQuestions(!showPreviouslyAskedQuestions);
    const takeScreenshot = (setFieldValue) => __awaiter(void 0, void 0, void 0, function* () {
        toggleModalVisibility === null || toggleModalVisibility === void 0 ? void 0 : toggleModalVisibility();
        const dataUrl = yield toPng(document.body, { cacheBust: true });
        setFieldValue('screenShot', dataUrl);
        toggleModalVisibility === null || toggleModalVisibility === void 0 ? void 0 : toggleModalVisibility();
    });
    const deleteScreenshot = (setFieldValue) => {
        setFieldValue('screenShot', '');
    };
    const handleSubmit = ({ screenShot, viaEmail, viaPhone, email, phone, details }, { validateForm, setFieldError }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const errors = yield validateForm();
            if (Object.keys(errors).length) {
                return;
            }
            yield contactTeam({
                screenShot,
                viaEmail,
                viaPhone,
                email,
                phone,
                details,
                userId,
            }).unwrap();
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
        }
        catch (err) {
            if (err.data.errors) {
                Object.keys(err.data.errors).forEach((key) => {
                    Object.keys(initialFormValues).forEach((valueKey) => {
                        if (key.includes(valueKey)) {
                            setFieldError(valueKey, formatMessage(globalMessages.invalidValueProvided, { value: valueKey }));
                        }
                    });
                });
            }
            else {
                notificationAlertHandler(formatMessage(globalMessages.serverUnavailable));
            }
        }
    });
    return (React.createElement(Formik, { validateOnMount: false, validateOnBlur: false, validateOnChange: true, initialValues: initialFormValues, validationSchema: ContactUsSchema, onSubmit: handleSubmit }, ({ values, errors, touched, submitCount, setFieldValue, validateField }) => {
        const formIsSubmitted = submitCount > 0;
        return (React.createElement(FormikForm, null,
            React.createElement(NeedHelpSection, null,
                React.createElement(NeedHelpHeading, { hasError: Boolean(values.viaEmail === false && values.viaPhone === false) }, formatMessage(globalMessages.howToBeReached)),
                React.createElement(CheckboxWrapper, null,
                    React.createElement(Checkbox.Formik, { name: "viaEmail", label: formatMessage(globalMessages.email), id: "email" }),
                    React.createElement(ContactUsInputWrapper, { isDisabled: !values.viaEmail },
                        React.createElement(Input.Formik, { name: "email", readOnly: Boolean(userSelectedEmail), autoFocus: Boolean(!userSelectedEmail && values.viaEmail), showError: Boolean(errors.email && touched.email), width: "100%" }))),
                React.createElement(CheckboxWrapper, null,
                    React.createElement(Checkbox.Formik, { id: "phone", name: "viaPhone", label: formatMessage(globalMessages.telephone) }),
                    React.createElement(FlexContainer, { direction: "row", gap: theme.spacing(1), alignItems: "flex-end" },
                        React.createElement(ContactUsInputWrapper, null,
                            React.createElement(Input.Formik, { name: "phone", inputFieldHeight: "35px", disabled: !values.viaPhone, showError: Boolean(errors.phone && touched.phone), label: formatMessage(globalMessages.telephone), fadedPlaceholder: true, placeholder: formatMessage(globalMessages.phonePlaceholder), replacePattern: new RegExp(/[^0-9+]/g), onBlur: () => {
                                    var _a, _b;
                                    try {
                                        const newPhone = (_b = parsePhoneNumber((_a = String(values.phone)) !== null && _a !== void 0 ? _a : '', {
                                            defaultCountry: DEFAULT_COUNTRY_CODE,
                                        })) === null || _b === void 0 ? void 0 : _b.formatInternational();
                                        setFieldValue('phone', newPhone);
                                    }
                                    catch (e) { }
                                }, onChange: () => {
                                    formIsSubmitted && validateField('phone');
                                } }))))),
            enableScreenshotFunctionality && (React.createElement(ScreenshotWrapper, null,
                React.createElement(Input, { name: "screenShot", type: "hidden" }),
                React.createElement(ScreenshotButtonsWrapper, null,
                    React.createElement(Button, { onClick: () => takeScreenshot(setFieldValue) }, values.screenShot
                        ? formatMessage(globalMessages.retakeScreenShot)
                        : formatMessage(globalMessages.takeScreenShot)),
                    React.createElement(Button, { onClick: () => deleteScreenshot(setFieldValue) }, formatMessage(globalMessages.deleteScreenshot)),
                    React.createElement(ToggleSwitch, { leftLabel: globalMessages.text, rightLabel: globalMessages.chatHistory, name: "questionsHistory", leftIsActive: !showPreviouslyAskedQuestions, setLeftIsActive: handleToggleView })),
                values.screenShot && (React.createElement(ScreenshotImageWrapper, null,
                    React.createElement(StyledScreenshotImage, { src: values.screenShot, alt: "Screenshot", onClick: handleOpenPicture }))),
                showFullScreenImage && values.screenShot && (React.createElement(FullScreenshotImage, { onClick: () => setShowFullScreenImage(false) },
                    React.createElement(StyledBigScreenshotImage, { src: values.screenShot, alt: "Screenshot" }))))),
            showPreviouslyAskedQuestions ? (React.createElement(React.Fragment, null, userQuestionsHistory.length === 0 ? (React.createElement(FlexContainer, { alignItems: "flex-start", height: "30vh" },
                React.createElement(Paragraph, null, formatMessage(globalMessages.noHistoryItems)))) : (React.createElement(UserPreviouslyAskedQuestions, null, userQuestionsHistory.map((question) => (React.createElement(UserPreviouslyAskedQuestionWrapper, { key: question.createdOn },
                React.createElement(UserPreviouslyAskedQuestion, null, question.details),
                React.createElement(UserQuestionCreatedDate, null,
                    formatMessage(globalMessages.submittedOn),
                    ' ',
                    question.createdOn ? new Date(question.createdOn).toUTCString() : '')))))))) : (React.createElement(TextArea, { name: "details", minHeight: "30vh", infoText: infoText, placeholder: placeholder, showError: Boolean(errors.details && touched.details) })),
            React.createElement(ButtonsSection, null,
                React.createElement(Button, { variant: "primary", onClick: closeModal }, formatMessage(globalMessages.cancel)),
                React.createElement(Button, { type: "submit" }, formatMessage(globalMessages.submit)))));
    }));
};
export default ContactUsForm;
