import { createSelector } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { userSelector } from 'store';
import { isElectronicSignatureAllowed, sortForms } from 'utils/forms';
import { ENVELOPE_STATE_MAPPER, SIGNATORY_STATE_MAPPER } from 'utils/mappers';
export const formsStateSelector = (state) => state.formsState;
export const selectedFormSelector = (state) => state.formsState.selectedForm;
export const formsSelector = createSelector([formsStateSelector, userSelector], ({ forms: allForms = [] }, { language, counterparties = [] }) => {
    const forms = sortForms(allForms, counterparties, language);
    const clientFormsObj = {};
    return {
        allForms: forms,
        allFormsCompleted: forms.every((form) => form.completed),
        globalForms: forms.filter((form) => form.isGlobal),
        clientSpecificForms: counterparties
            .slice()
            .sort((a, b) => a.legalName.localeCompare(b.legalName))
            .reduce((result, { legalName, id }) => {
            const counterPartyRelatedForms = forms.filter((f) => { var _a; return !f.isGlobal && ((_a = f.counterpartyIds) === null || _a === void 0 ? void 0 : _a.includes(id)); });
            if (counterPartyRelatedForms.length) {
                result[legalName] = counterPartyRelatedForms;
            }
            return result;
        }, clientFormsObj),
    };
});
export const allFormsCompletedSelector = createSelector(formsStateSelector, ({ forms }) => Boolean(Array.isArray(forms) && forms.every((form) => form.completed)));
export const formEnvelopesSelector = ({ formsState }) => formsState.envelopes;
export const envelopesStateProgressSelector = createSelector([formEnvelopesSelector], (envelopes) => {
    const allEnvelopes = envelopes || [];
    const allSignatories = allEnvelopes.map((envelope) => envelope.signatories).flat();
    const signatoriesWhoSigned = allSignatories.filter((signatory) => [SIGNATORY_STATE_MAPPER.COMPLETED, SIGNATORY_STATE_MAPPER.SIGNED].includes(signatory.state));
    if (allSignatories.length === 0) {
        return 0;
    }
    return Math.round((signatoriesWhoSigned.length / allSignatories.length) * 100);
});
export const allFormEnvelopesSignedSelector = ({ formsState }) => {
    var _a;
    return Boolean(((_a = formsState.envelopes) === null || _a === void 0 ? void 0 : _a.length) &&
        formsState.envelopes.every((envelope) => envelope.state === ENVELOPE_STATE_MAPPER.COMPLETED));
};
export const uniqueSignatoriesSelector = createSelector([formEnvelopesSelector], (envelopes) => {
    const allEnvelopes = envelopes || [];
    const allSignatories = allEnvelopes
        .map((envelope) => {
        return envelope.signatories || [];
    })
        .flat();
    return uniqBy(allSignatories, 'identifier');
});
export const userShouldBeAskedForTaxIdSelector = createSelector([userSelector, formsSelector], (user, forms) => {
    return !user.hasTaxId && forms.allForms && forms.allForms.some((form) => form.taxIdRelevant);
});
export const userHasNonClickThroughFormsToSign = createSelector([formsSelector], ({ allForms }) => {
    return Boolean((allForms === null || allForms === void 0 ? void 0 : allForms.length) &&
        (allForms === null || allForms === void 0 ? void 0 : allForms.some(({ signatorySource }) => isElectronicSignatureAllowed(signatorySource))));
});
