// ========================================================================
// THEME/Z-INDEX
// ========================================================================
export default {
    notification: 200,
    loader: 150,
    tooltip: 120,
    toolbar: 105,
    modal: 101,
    overlays: 100,
    buttonSelect: 50,
    dropdown: 25,
    href: 5,
    icon: 10,
};
