import React from 'react';
import { globalMessages, onboardingRequestsMessages } from 'i18n/messages';
import { TextContent } from 'shared/styled/Texts';
//TODO: optimise the mappers
export const methodOfConfirmationMapper = {
    1: { id: 1, label: 'Unibank', name: 'Unibank', value: 1 },
    2: { id: 2, label: 'Multibank', name: 'Multibank', value: 2 },
};
export const TRADING_PURPOSE = {
    UNKNOWN: 0,
    HEDGING: 1,
    SPECULATION: 2,
};
export const TRADING_PRODUCTS = {
    UNKNOWN: 0,
    SPOT: 1,
    FORWARD: 2,
};
export const BANK_METHODS_CONFIRMATION = {
    // currently limited to only UNIBANK & MULTIBANK
    UNKNOWN: 0,
    UNIBANK: 1,
    MULTIBANK: 2,
    SWIFT_CODE: 3,
    EMAIL_ADDRESS: 4,
};
export const TRADING_EXPERIENCE = {
    UNKNOWN: 0,
    LESS_THAN_FIVE: 1,
    MORE_THAN_FIVE: 2,
};
export const radioButtonValueMapper = {
    // @TODO revisit for refactor
    number: (value) => Number(value),
    string: (value) => String(value),
    boolean: (value) => value === 'true',
};
export const AUTH_CHANNEL_MAPPER = {
    UNKNOWN: 0,
    PUK: 1, // PUK(Unternehmenkunden)
    FK: 2, // FK(Firmenkunden)
};
export const AUTH_CHANNEL_MAPPER_REVERSED = {
    0: 'UNKNOWN',
    1: 'PUK',
    2: 'FK',
};
export const PORTALS_URLS_MAPPER = {
    CORPORATE_CLIENT_URL: 'Url_Firmenkunden',
    BUSINESS_CLIENT_URL: 'Url_Unternehmerkunden',
    CORPORATE_CLIENT_ROOT_URL: 'Url_FirmenkundenRoot',
    BUSINESS_CLIENT_ROOT_URL: 'Url_UnternehmerkundenRoot',
    LIVE_TRADER_URL: 'Url_FxLiveTrader',
    LIVE_CONFIRM_URL: 'Url_LiveConfirm',
    LIVE_TRADER_VIDEO_URL_EN: 'Url_FxLiveTraderVideo_EN',
    LIVE_TRADER_VIDEO_URL_DE: 'Url_FxLiveTraderVideo_DE',
    TRADING_PRODUCTS_VIDEO_GUIDES_URL_EN: 'Url_VideoGuides_EN',
    TRADING_PRODUCTS_VIDEO_GUIDES_URL_DE: 'Url_VideoGuides_DE',
};
export const USER_MAPPER = {
    UNKNOWN: 0,
    CLIENT: 1,
    EMPLOYEE: 2,
};
export const USER_TENANT_MAPPER = {
    OTHER: -1,
    UNKNOWN: 0,
    COBA_INLAND: 1,
    TESTFILIALE_MAINZ: 2,
    COBA_AG_INLAND_PCPK_FILIALE: 44,
};
export const OVERALL_STATUS = {
    UNKNOWN: 0,
    INITIAL: 1,
    LEGAL_CONFIGURATION: 2,
    LEGAL_COMPLETION: 3,
    ONBOARDING: 4,
    COMPLETED: 5,
    COMPLETED_TOURIST: 6,
    LEGAL_VERIFICATION: 7,
};
export const ENVELOPES_VALIDATION_PAGE_ACCESSIBLE_STATUSES = [
    OVERALL_STATUS.LEGAL_COMPLETION,
    OVERALL_STATUS.LEGAL_VERIFICATION,
];
export const ONBOARDING_PAGE_ACCESSIBLE_STATUSES = [
    OVERALL_STATUS.ONBOARDING,
    OVERALL_STATUS.COMPLETED,
];
export const ONBOARDING_PRODUCTS = {
    UNKNOWN: 0,
    FX_LIVE_TRADER: 1,
    LIVE_CONFIRM: 2,
    FX_LIVE_TRADER_AND_LIVE_CONFIRM: 3,
};
export const ONBOARDING_REQUEST_STATUS_MAPPER = {
    UNKNOWN: 0,
    INITIAL: 1,
    VALIDATED: 2,
    LEGAL: 3,
    ONBOARDING: 4,
    COMPLETED: 5,
    CANCELLED: 6,
    ARCHIVED: 7,
};
export const productMapper = {
    [ONBOARDING_PRODUCTS.LIVE_CONFIRM]: 'Live Confirm',
    [ONBOARDING_PRODUCTS.FX_LIVE_TRADER]: 'FX Live Trader',
    [ONBOARDING_PRODUCTS.FX_LIVE_TRADER_AND_LIVE_CONFIRM]: 'FX Live Trader & Live Confirm',
};
export const ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER = {
    UNKNOWN: 0,
    AVAILABLE: 1,
    REQUESTED: 2,
    ENABLED: 3,
    MISSING_POWER_OF_ATTORNEY: 4,
};
export const SIGNATORY_PERMISSION_MAPPER = {
    UNKNOWN: 0,
    SIGN_ALONE: 1,
    SIGN_TOGETHER: 2,
    NOT_ALLOWED: 3,
};
export const SIGNATORY_SOURCE_MAPPER = {
    UNKNOWN: 0,
    AUTOMATIC: 1,
    MANUAL_OUT_OF_SCOPE: 2,
    MANUAL_DUE_TO_ERROR: 3,
    MANUAL_AD_HOC: 4, // manually created
    PROHIBITED_OUT_OF_SCOPE_JURISDICTION: 5,
    PROHIBITED_LEGAL_FORM: 6,
};
export const SIGNATORY_STATE_MAPPER = {
    PENDING_LEGITIMISATION: -1,
    UNKNOWN: 0,
    AUTO_RESPONDED: 1,
    COMPLETED: 2,
    CREATED: 3,
    DECLINED: 4,
    DELIVERED: 5,
    FAX_PENDING: 6,
    SENT: 7,
    SIGNED: 8,
};
export const LEGAL_FORM_ACCEPTANCE_TYPE_MAPPER = {
    UNKNOWN: 0,
    CLICK_THROUGH: 1,
    DOCU_SIGN_SIMPLE: 2,
    DOCU_SIGN_ADVANCED: 3,
    DOCU_SIGN_QUALIFIED: 4,
};
export const DELETABLE_AND_EDITABLE_SIGNATORY_SOURCES = [
    SIGNATORY_SOURCE_MAPPER.MANUAL_OUT_OF_SCOPE,
    SIGNATORY_SOURCE_MAPPER.MANUAL_DUE_TO_ERROR,
    SIGNATORY_SOURCE_MAPPER.MANUAL_AD_HOC,
];
export const FORBIDDEN_SIGNATORY_SOURCES = [
    SIGNATORY_SOURCE_MAPPER.UNKNOWN,
    SIGNATORY_SOURCE_MAPPER.PROHIBITED_OUT_OF_SCOPE_JURISDICTION,
    SIGNATORY_SOURCE_MAPPER.PROHIBITED_LEGAL_FORM,
];
export const FORMS_WITH_ALLOWED_E_SIGNATURE = [
    SIGNATORY_SOURCE_MAPPER.AUTOMATIC,
    SIGNATORY_SOURCE_MAPPER.MANUAL_OUT_OF_SCOPE,
    SIGNATORY_SOURCE_MAPPER.MANUAL_DUE_TO_ERROR,
    SIGNATORY_SOURCE_MAPPER.MANUAL_AD_HOC,
];
export const ENVELOPE_STATE_MAPPER = {
    NONE: 0,
    CREATED: 1,
    SENT: 2,
    DELIVERED: 3,
    SIGNED: 4,
    DECLINED: 5,
    VOIDED: 6,
    COMPLETED: 7,
};
export const ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER_TRANSLATIONS = {
    0: globalMessages.Unknown,
    1: globalMessages.AvailableProductInfo,
    2: globalMessages.RequestedProductInfo,
    3: globalMessages.EnabledProductInfo,
    4: globalMessages.MissingPowerOfAttorneyProductInfo,
};
export const productOptionsMapper = (productAvailability, translator) => {
    return {
        [ONBOARDING_PRODUCTS.FX_LIVE_TRADER_AND_LIVE_CONFIRM]: {
            id: ONBOARDING_PRODUCTS.FX_LIVE_TRADER_AND_LIVE_CONFIRM,
            label: React.createElement(TextContent, {
                grayOut: productAvailability !== ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER.AVAILABLE,
            }, `${translator(onboardingRequestsMessages.liveTraderAndLiveConfirm)}`, productAvailability !== ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER.AVAILABLE &&
                React.createElement(TextContent, { fontSize: '0.65rem', fontStyle: 'italic', grayOut: false }, ` (${translator(ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER_TRANSLATIONS[productAvailability])})`)),
            name: translator(onboardingRequestsMessages.liveTraderAndLiveConfirm),
            value: ONBOARDING_PRODUCTS.FX_LIVE_TRADER_AND_LIVE_CONFIRM,
            isDisabled: productAvailability !== ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER.AVAILABLE,
        },
        [ONBOARDING_PRODUCTS.LIVE_CONFIRM]: {
            id: ONBOARDING_PRODUCTS.LIVE_CONFIRM,
            label: React.createElement(TextContent, { grayOut: productAvailability !== ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER.AVAILABLE }, `${translator(onboardingRequestsMessages.liveConfirm)}`, productAvailability !== ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER.AVAILABLE &&
                React.createElement(TextContent, { fontSize: '0.65rem', fontStyle: 'italic', grayOut: false }, ` (${translator(ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER_TRANSLATIONS[productAvailability])})`)),
            name: translator(onboardingRequestsMessages.liveConfirm),
            value: ONBOARDING_PRODUCTS.LIVE_CONFIRM,
            isDisabled: productAvailability !== ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER.AVAILABLE,
        },
        [ONBOARDING_PRODUCTS.FX_LIVE_TRADER]: {
            id: ONBOARDING_PRODUCTS.FX_LIVE_TRADER,
            label: React.createElement(TextContent, { grayOut: productAvailability !== ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER.AVAILABLE }, `${translator(onboardingRequestsMessages.liveTrader)}`, productAvailability !== ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER.AVAILABLE &&
                React.createElement(TextContent, { fontSize: '0.65rem', fontStyle: 'italic', grayOut: false }, ` (${translator(ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER_TRANSLATIONS[productAvailability])})`)),
            name: translator(onboardingRequestsMessages.liveTrader),
            value: ONBOARDING_PRODUCTS.FX_LIVE_TRADER,
            isDisabled: productAvailability !== ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER.AVAILABLE,
        },
    };
};
