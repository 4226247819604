import { FORMS_WITH_ALLOWED_E_SIGNATURE, LEGAL_FORM_ACCEPTANCE_TYPE_MAPPER } from './mappers';
export const sortForms = (forms, counterparties, language) => {
    try {
        return structuredClone(forms)
            .map((form) => {
            var _a;
            const counterparty = counterparties.find((c) => { var _a; return (_a = form.counterpartyIds) === null || _a === void 0 ? void 0 : _a.includes(c.id); });
            return Object.assign(Object.assign({}, form), { availableSignatories: (_a = form === null || form === void 0 ? void 0 : form.availableSignatories) === null || _a === void 0 ? void 0 : _a.sort((first, second) => {
                    var _a, _b;
                    if (first.recommended && second.recommended) {
                        return (_a = first.fullNameReversed) === null || _a === void 0 ? void 0 : _a.localeCompare(second.fullNameReversed);
                    }
                    if (first.recommended) {
                        return -1;
                    }
                    if (second.recommended) {
                        return 1;
                    }
                    return (_b = first.fullNameReversed) === null || _b === void 0 ? void 0 : _b.localeCompare(second.fullNameReversed);
                }), counterpartyName: (counterparty === null || counterparty === void 0 ? void 0 : counterparty.legalName) || '' });
        })
            .sort((first, second) => {
            var _a, _b, _c, _d;
            if (first.isGlobal && second.isGlobal) {
                return (_b = (_a = first.title) === null || _a === void 0 ? void 0 : _a[language]) === null || _b === void 0 ? void 0 : _b.localeCompare((_c = second.title) === null || _c === void 0 ? void 0 : _c[language]);
            }
            if (first.isGlobal) {
                return -1;
            }
            if (second.isGlobal) {
                return 1;
            }
            return (_d = first.counterpartyName) === null || _d === void 0 ? void 0 : _d.localeCompare(second.counterpartyName);
        });
    }
    catch (err) {
        console.error(err);
        return forms;
    }
};
export const isElectronicSignatureAllowed = (signatorySource) => {
    return FORMS_WITH_ALLOWED_E_SIGNATURE.includes(signatorySource);
};
export const isDocuSignAdvancedForm = (acceptanceType) => {
    return LEGAL_FORM_ACCEPTANCE_TYPE_MAPPER.DOCU_SIGN_ADVANCED === acceptanceType;
};
