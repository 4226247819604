var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api, requester } from 'api/shared';
import { userApiEndpoints } from './userApi';
const FEEDBACK_ENDPOINTS = {
    reportProblem: () => '/feedback/problem',
    contactTeam: () => '/feedback/contact',
};
const feedbackApi = api.injectEndpoints({
    endpoints: (build) => ({
        reportProblem: build.mutation({
            query: (data) => requester.post(FEEDBACK_ENDPOINTS.reportProblem(), data),
        }),
        contactTeam: build.mutation({
            query: (data) => requester.post(FEEDBACK_ENDPOINTS.contactTeam(), data),
            onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        yield queryFulfilled;
                        const { decodedAuthTokenNameId = '' } = getState().auth;
                        yield userApiEndpoints.getUser.initiate(decodedAuthTokenNameId, { forceRefetch: true })(dispatch, getState, _);
                    }
                    catch ({ error }) {
                        console.log(error);
                    }
                });
            },
        }),
    }),
});
export const feedbackApiEndpoints = feedbackApi.endpoints;
export const { useReportProblemMutation, useContactTeamMutation } = feedbackApi;
